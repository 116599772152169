import React from "react";
import styled from "styled-components";

const content03 = () => {
  return (
    <Container>{`금융 전문가와 \n AI가 분석하는 \n 정교한 \n 투자 데이터`}</Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 148px;
  height: 112px;
  padding: 16px;
  box-sizing: border-box;
  background-image: linear-gradient(
    133deg,
    rgba(220, 169, 251, 0.8) -4.8%,
    rgba(255, 0, 77, 0.8) 96.57%
  );
  white-space: pre-line;
  justify-content: center;

  color: #fff;

  font-family: Pretendard900;
  font-weight: 900;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
`;
export default content03;
