import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import channelService from '../utils/channelService'

type LocationState = {
  prev?: string
}
const ChannelTalkManager: React.FC = () => {
  const location = useLocation<LocationState>()
  const prev = location.state?.prev

  useEffect(() => {
    channelService.loadScript()
    const pathsToShowChannelTalk = ['/', '/calendar', '/info', '/faq', '/new-main', '/mobile-main']
    const pathsToOpen = ['/']
    //test코드
    // const pathsToShowChannelTalk = ['/main-test']
    // const pathsToOpen = ['/main-test']

    if (pathsToShowChannelTalk.includes(location.pathname)) {
      channelService.boot({
        pluginKey: process.env.REACT_APP_CHANNELTALK_KEY!,
      })
      if (
        pathsToOpen.includes(location.pathname) &&
        prev &&
        prev !== '/inquire'
      ) {
        channelService.showMessenger()
      }
    } else {
      channelService.shutdown()
    }

    return () => {
      channelService.shutdown()
    }
  }, [location])

  return null
}

export default ChannelTalkManager
