import React from 'react'
import styled from 'styled-components'
import ImgContainer from './ImgContainer'
import Title from '@components/NewMain/Title'

const index = () => {
  return (
    <Container>
      <Title title={`공모주 투자를 \n 쉽게, 편리하게`} />
      <ImgContainer />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  min-width: 1280px;

`

export default index
