import React from "react";
import styled from "styled-components";

type NameProps = {
  name: string;
  url?: string;
};

const Menu = ({ name, url }: NameProps) => {
  return <Container onClick={() => window.open(url)}>{name}</Container>;
};

const Container = styled.div`
  width: auto;
  height: auto;
  font-size: 18px;
  font-style: normal;
  cursor: pointer;
`;

export default Menu;
