import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

const Content02 = () => {
  return (
    <Container>
      {`금융 전문가와 AI가 \n 분석하는 \n 정교한 \n 투자 데이터`}
    </Container>
  );
};

const Container = styled.div`
  grid-area: box2;
  display: flex;
  align-items: center;
  white-space: pre-line;
  padding: 3.5rem 2.5rem;
  border-radius: 2rem;
  box-sizing: border-box;
  height: 19rem;
  background-image: linear-gradient(
    133deg,
    rgba(220, 169, 251, 0.8) -4.8%,
    rgba(255, 0, 77, 0.8) 96.57%
  );

  color: #fff;
  font-size: 40px;
  font-family: Pretendard700;
  line-height: normal;
`;

export default Content02;
