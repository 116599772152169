import React, {useState} from 'react'
import styled from 'styled-components'
 

const MobileDownload = () => {

  const [deviceType, setDeviceType] = useState('Unknown Device');



    const detectDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        const platform = navigator.platform;
    
        if (/android/i.test(userAgent)) {
        return 'Android';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        return 'iOS';
        } else if (platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
        return 'iOS'; 
        } else {
        return 'Other';
        }
    };


    const redirectToPlatformLink = () => {
        const device = detectDevice();
        setDeviceType(device);
      
        if (device === 'Android') {
          try {
            window.open('https://play.google.com/store/apps/details?id=com.qniverse.ipo');
          } catch (error) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.qniverse.ipo';
          }
        } else if (device === 'iOS') {
          // try {
          //   window.webkit.messageHandlers.openDeepLink.postMessage('https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484');
          // } catch (error) {
          //   window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484';
          // }
          window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484';
      
      
        } else {
          try {
            window.open('https://qr-efbc6.web.app/');
          } catch (error) {
            window.location.href = 'https://qr-efbc6.web.app/';
          }
        }
      };

      

  return (
    <ButtonContainer>
        <AppDownload onClick={redirectToPlatformLink}>
        일육공 다운로드
        </AppDownload>
    </ButtonContainer>
  )
}

const AppDownload = styled.div`
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0E6DFB;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 18px;
  padding: 16px 24px;
  box-sizing: border-box;
  min-width: 312px;
  width: 100%;
`


const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border-top: 1px solid #DCDEEB;
  background-color: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 500;
`


export default MobileDownload
