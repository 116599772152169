import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Download from '../Download'
import Menu from './Menu'
import DownloadApp from '../DownloadApp'
import { Link } from 'react-router-dom'
const Index = () => {

  const [isClicked, setIsClicked] = useState(false);

  const handleToggleDownloadApp = () => {
    setIsClicked(!isClicked);
  };

  const newMainPage = window.location.href.includes('new-main')


  return (

      <Container>
        <Link to={newMainPage ? "/new-main" : "/"}>
          <LogoImg src={'/img/160_logo.svg'} alt='160_logo'/>
        </Link>
        <RightContainer>
          <MenuContainer>
            <Menu name='엠엘투자자문↗' url='https://www.ml-invest.co.kr/' />
            {/* <Menu name='1:1 문의' url='https://160ipo.com/inquire' /> */}
            <DownloadButton onClick={handleToggleDownloadApp}>앱 다운로드</DownloadButton>
          </MenuContainer>
          {isClicked && <DownloadApp isClicked={isClicked} setIsClicked={setIsClicked} />}
        </RightContainer>
      </Container>
  )
}

const DownloadButton = styled.div`
  background-color: #0e6dfb;
  padding: 8px 8px;
  width: auto;
  height: auto;
  border-radius: 8px;
  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

`

const Container = styled.div`
  width: 100%;
  height: 79px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #c9d3de;
  z-index: 300;
  min-width: 360px;
`

const LogoImg = styled.img``
const MenuContainer = styled.div`
  display: flex;
  padding: 8px 8px;
  box-sizing: border-box;
  gap: 24px;
  align-items: center;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`

export default Index
