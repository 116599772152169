import React from "react";
import styled from "styled-components";
import CircleMotion from "./circleMotion";

const Index = () => {
  return (
    <Container>
      <Title>{`여러 증권사의 공모주를 \n 검증된 AI로 \n 우량 종목만 선별하여`}</Title>
      <CircleMotion />
    </Container>
  );
};

const Container = styled.div`
  height: 418px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #F5F6F8;
`;
const Title = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  font-family: Pretendard900;
  font-weight: 900;
`;

export default Index;
