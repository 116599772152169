import React from "react";
import styled from "styled-components";
import Rolling from "./rolling";

const ImgContainer = () => {
  return (
    <Container>
      <CenterImg src="/img/Center-main.svg" />
      <Rolling />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 102.81px;
`;

const CenterImg = styled.img`
  width: 212px;
  z-index: 150;
  position: absolute;
`;

export default ImgContainer;
