import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import TabletEvent from "../../NewTabletPage/index"
import { useLocation } from 'react-router-dom';



const Index = () => {

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isWeb = searchParams.get('isWeb') === 'true';

  const [isTablet, setIsTablet] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 768); 
    };

    handleResize();
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const [deviceType, setDeviceType] = useState('Unknown Device');


  const detectDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const platform = navigator.platform;
  
    if (/android/i.test(userAgent)) {
      return 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return 'iOS';
    } else if (platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      return 'iOS'; 
    } else {
      return 'Other';
    }
  };
  
  const redirectToPlatformLink = () => {
    const device = detectDevice();
    setDeviceType(device);
  
    if (device === 'Android') {
      try {
        window.open('https://play.google.com/store/apps/details?id=com.qniverse.ipo');
      } catch (error) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.qniverse.ipo';
      }
    } else if (device === 'iOS') {
      // try {
      //   window.webkit.messageHandlers.openDeepLink.postMessage('https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484');
      // } catch (error) {
      //   window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484';
      // }
      window.location.href = 'https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484';


    } else {
      try {
        window.open('https://qr-efbc6.web.app/');
      } catch (error) {
        window.location.href = 'https://qr-efbc6.web.app/';
      }
    }
  };

  const MissionList = [
    {
      number: '미션1',
      content:
        '일육공에서 삼성증권 투자일임계약하고 삼성증권 계좌를 입출금계좌로 지정',
    },
    {
      number: '미션2',
      content: '삼성증권 계좌에 100만원 입금',
    },
    {
      number: '미션3',
      content: '일육공 공모주 자동청약 참여(최소 1회)',
    },
  ]

  const GuideList = [
    '* 일육공은 엠엘투자자문(주)의 공모주 간편투자 앱입니다.',
    '* 삼성증권 투자일임계약 시, 일육공에서 삼성증권 신규계좌가 개설됩니다.',
    '* 경품은 미션 완료 여부에 따라 지급되며, 공모주 배정결과와 무관합니다.',
    '* 경품은 이벤트 종료 후 10 영업일 이내에 지급되며, 대상 고객 휴대폰으로 개별 발송됩니다.',
  ]

  const NoticeList = [
    {
      title: '확인해 주세요!',
      content: [
        '본 이벤트 기간은 2024.9.27.(07:00) ~ 2024.12.31.(19:00)입니다.',
        '본 이벤트에 참여하여 미션 조건을 모두 충족한 고객중 선착순으로 5,000명에 한하여 경품을 지급받게 됩니다. 본 모바일 경품은 연장, 재발송, 환불 등이 불가합니다.',
        '본 이벤트의 경품은 당첨자 본인 명의의 휴대폰으로만 수령가능하며, 중복 응모하는 등 부당한 방법을 취한 경우 경품 지급대상에서 제외될 수 있습니다.',
        '본 이벤트 기간중 고객정보 입력오류(연락처 결번, 착신정지, 수신정지 등)에 따른 경품 미지급에 대한 책임은 고객에게 있고, 경품 지급에서 제외될 수 있습니다.',
        '본 이벤트를 통해 수집된 개인정보(이름, 휴대폰 번호등)는 이벤트 당첨자 선정/경품 지급을 위해서만 활용되며, 이벤트 종료(경품 발송 완료)후 즉시 폐기됩니다.',
        '본 이벤트의 경품으로 지급된 가액은 고객님의 기타소득에 포함될 수 있으며 연간 300만원 초과시 종합소득세 신고대상이 될 수 있습니다.',
        '본 이벤트에서 지급하는 경품은 일육공의 투자일임계약 운용결과 발생하는 투자손익과 무관하며 고객에 대한 재산상의 이익제공에 해당될 수 있습니다.',
        '본 이벤트와 관련한 원활한 메시지 수신을 위해 알림 설정 활성화를 확인해 주시기 바라며, 마케팅 정보 활용에 동의(체크)한 고객을 대상으로 본 이벤트가 진행됩니다.',
        '본 이벤트는 당사 부득이한 사정 또는 금융당국의 지도 및 권고에 의해 경품 지급이 조기 종료되거나 변경될 수 있습니다. (단, 기 미션 완료 고객 및 이벤트 당첨 고객은 제외)',
        '법인 고객 및 엠엘투자자문 임직원은 본 이벤트 경품 지급대상에서 제외되며, 본 이벤트는 삼성증권과 엠엘투자자문이 제휴하여 진행하는 이벤트로 관련 문의는 엠엘투자자문(02-6949-0045) 또는 『일육공(160)』 앱 > 투자 > 챗봇으로 해 주시기 바랍니다.',
        '본 이벤트는 신세계백화점과 무관하며 해당 이벤트에 일절 관여하지 않습니다.'
      ],
    },
    {
      title: '유의 사항',
      content: [
        '투자자는 금융투자상품에 대하여 증권사로부터 충분한 설명을 받을 권리가 있으며, 투자전 상품설명서 등을 반드시 읽어보시기 바랍니다.',
        '이 금융상품은 예금자보호법에 따라 보호되지 않습니다.',
        '금융투자상품은 자산가격 변동, 환율 변동, 신용등급 하락 등에 따라 투자원금의 손실(0~100%)이 발생할 수 있으며, 그 손실은 투자자에게 귀속됩니다.',
        '주식거래 수수료는 0.078%~1.7%이며, 기타 자세한 사항은 홈페이지 등을 참고하시기 바랍니다.',
        '본 안내는 청약의 권유를 목적으로 하지 않으며, 청약의 권유는 (예비,간이)투자설명서에 따릅니다.',
        '일반청약자에게 복수 배정방식(균등방식 + 비례방식 등)이 적용되어 각 방식에 따른 배정결과가 다를 수 있습니다.',
        'IPO공모주식은 상장초기 가격변동성이 크며, 상장 후 주가가 공모가격을 하회하여 투자손실이 크게 발생할 수 있습니다.',
        '본 투자일임계약은 투자자의 독자적인 판단으로 투자일임업자와 계약을 체결하는 것이며 삼성증권과 무관합니다.',
        '본 투자일임계약의 수수료는 계약체결일 이후 매 6개월마다 손익을 합산하여 수익이 있는 경우 수익의 15%가 부과됩니다.(자세한 사항은 투자일임계약서 참조)',
        '일육공 서비스는 엠엘투자자문 주식회사에서 제공하는 서비스입니다. '
      ],
    },
  ]

  return (
    <>
    {isTablet ? <TabletEvent />  :  <Container>
          {/* <Header>삼성증권 공모주 투자 이벤트</Header> */}
          <Section1>
            <Logo src={'/img/samsung/ml_logo_w.svg'} />
            <CrossBrand>일육공 X 삼성증권</CrossBrand>
            <Title>{`공모주 간편투자 \n 제휴 이벤트`}</Title>
            <Content>{`공모주 투자일임계약하고 신세계 \n 모바일 상품권 최대 4만원 받으세요.`} 
              <Explanation>
              (선착순 5,000명)
              </Explanation>
              </Content>
              <IconImgs>
            <GiftCard src={'/img/samsung/gift-card.svg'} />
            {/* <Heart src={'/img/samsung/gift-heart.svg'} /> */}
              </IconImgs>
          </Section1>
          <Section2>
            <Period>
              <SubTitle>이벤트 기간</SubTitle>
              <SubContent>2024년 9월 27일 ~ 12월 31일</SubContent>
            </Period>
            <Participation>
              <SubTitle>참여방법</SubTitle>
              <MissionContainer>
                {MissionList.map((item) => (
                  <Mission>
                    <MissionNumber>{item.number}</MissionNumber>
                    <MissionContent>{item.content}</MissionContent>
                  </Mission>
                ))}
              </MissionContainer>
              <SubTitleContainer>
                <SubTitle>혜택</SubTitle>
                <FCFS>* 선착순 5,000명 지급</FCFS>
              </SubTitleContainer>
              <Favor>
                <Party src={'/img/samsung/Party.svg'}/>
                <Top>
                  미션 모두 완료 시<Circle>
                    <First1>2만원</First1>
                    <Second>상품권</Second>
                  </Circle>
                </Top>
                <Bottom>
                  <LeftContent>
                    <BottomTitle>추가 혜택</BottomTitle>
                    <BottomContent>
                      {`삼성증권 생애최초 \n 신규계좌 고객인 경우`}
                    </BottomContent>
                  </LeftContent>
                  <Sticker>
                    <First>2만원</First>
                    <Second>상품권 추가</Second>
                  </Sticker>
                </Bottom>
              </Favor>
            </Participation>
            <Guide>
              {GuideList.map((item, index) => (
                <GuideContent key={index} isFirst={index === 0}>
                  {item}
                </GuideContent>
              ))}
            </Guide>
          </Section2>
          <Section3>
            {NoticeList.map((itm, itmIndex) => {
              if (itm.title === '확인해 주세요!') {
                return (
                  <React.Fragment key={itmIndex}>
                    <NoticeTitle>{itm.title}</NoticeTitle>
                    <Notice>
                      {Array.isArray(itm.content) &&
                        itm.content.map((contentItem, index) => (
                          <ContentContainer key={index}>
                            <Dot>·</Dot>
                            <NoticeContent>{contentItem}</NoticeContent>
                          </ContentContainer>
                        ))}
                    </Notice>
                  </React.Fragment>
                )
              } else if (itm.title === '유의 사항') {
                return (
                  <React.Fragment key={itmIndex}>
                    <NoticeTitle>{itm.title}</NoticeTitle>
                    <Notice>
                      {Array.isArray(itm.content) &&
                        itm.content.map((contentItem, index) => (
                          <ContentContainer key={index}>
                            <Dot>·</Dot>
                            <NoticeContent>{contentItem}</NoticeContent>
                          </ContentContainer>
                        ))}
                    </Notice>
                  </React.Fragment>
                )
              }
              return null
            })}
            <HI>
            한국금융투자협회 심사필 제24-03913호 (2024-09-30 ~ 2025-09-29)
            </HI>
          </Section3>
          {isWeb ? (
            <ButtonContainer isScrolled={isScrolled} onClick={redirectToPlatformLink}>
              <AppDownload>이벤트 참여하러가기</AppDownload>
            </ButtonContainer>
          ) : <ButtonContainer isScrolled={isScrolled} onClick={redirectToPlatformLink}>
          <AppDownload>이벤트 참여하러가기</AppDownload>
        </ButtonContainer>

        // <ButtonContainer isScrolled={isScrolled} onClick={() => window.open('https://qr-efbc6.web.app/')}>
        //   <AppDownload>이벤트 참여하러가기</AppDownload>
        // </ButtonContainer> 
        }
        </Container>}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const First1 = styled.div`
  color: #FFF;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`


const Header = styled.div`
  padding: 12px 24px;
  display: flex;

  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.33px;
`

const Section1 = styled.div`
  height: auto;
  padding: 72px 24px;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  white-space: pre-line;
  color: white;
  background-color: #0e6dfb;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 32px;
`

const Content = styled.div`
  color: rgba(255, 255, 255, 0.90);
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.36px;
`

const IconImgs = styled.div`
  position: relative;
  `

const Explanation = styled.div`
  color: #fff;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.8;
  margin-top: 4px;
`
const Mission = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #c5ccd6;
  padding: 16px 0px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }
`
const Title = styled.div`
  font-family: GmarketSans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

`
const Section2 = styled.div`
  padding: 64px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 56px;
`

const SubTitle = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const SubContent = styled.div`
  color: #555564;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Period = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Participation = styled.div``
const GiftCard = styled.img`
  min-width: 312px;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0px 28px 28px 0px rgba(0, 0, 0, 0.25);
`

const Heart = styled.img`
  position: absolute;
  z-index: 10;
  top: -20px;
  right: -20px;
  width: 13%;
`

const Party = styled.img`
  position: absolute;
  top: 0.49px;
  right: 3px;
  width: 40px;
`

const MissionContainer = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
`

const MissionNumber = styled.div`
  color: var(--brand-color-2-0, #0e6dfb);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  padding: 4px 4px;
  border-radius: 4px;
  border: 1px solid #bae6fd;
  width: 54px;
  justify-content: center;
  align-items: center;
  background-color: #e0f2fe;
  height: auto;

  font-family: Pretendard;

`
const MissionContent = styled.div`
  color: var(--ui-color-text-dark, #555564);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 257px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Favor = styled.div`
  border-radius: 16px;
  width: 100%;
  height: auto;
  padding: 16px 16px;
  background-image: linear-gradient(159deg, #f0f9ff -1.66%, #5e9fff 126.18%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  position: relative;
`

const Top = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Circle = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #0e6dfb;

  color: #fff;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Bottom = styled.div`
  border-radius: 8px;
  border: 1px solid #d2eafa;
  padding: 16px 12px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
`

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`

const GuideContent = styled.div<{ isFirst: boolean }>`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${(props) => (props.isFirst ? '#6f6e6e' : '#0E6DFB')};
`

const Section3 = styled.div`
  padding: 24px 16px 131px 16px;
  display: flex;
  flex-direction: column;
  background-color: #dbdfe9;
  gap: 13px;
  color: #808491;
  box-sizing: border-box;
`

const Dot = styled.div`
  padding: 0px 4px;
  box-sizing: border-box;
`

const NoticeTitle = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

const ContentContainer = styled.div`
  display: flex;
`

const Notice = styled.div`
  display: flex;
  flex-direction: column;
`

const NoticeContent = styled.div`
  color: #808491;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

const HI = styled.div`
  color: #808491;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

const CrossBrand = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.3);
  width: 118px;
  height: auto;
  color: #fff;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
justify-content: center;
  width: 146px;
  gap: 4px;
  color: #0e6dfb;
`
const BottomTitle = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const BottomContent = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`

const Sticker = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #e0f2fe;
  padding: 12px 16px;
  width: auto;
  color: #0e6dfb;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 126.73px;
`

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FCFS = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999ba9;
`

const First = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const Second = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const AppDownload = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: #0E26FB;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-family: Pretendard700;
  font-weight: 700;


`

const ButtonContainer = styled.div<any>`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    background-color: transparent;
    transition: background-color 0.3s ease;
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 16px 24px;
`

export default Index;
