import React from "react";
import styled from "styled-components";

const Index = () => {
  const list = [
    "투자자는 당사와 투자 일임계약을 체결하기 전에 투자 일임계약 권유 문서 및 투자 일임 계약서를 반드시 읽어 보시기 바랍니다.",
    "공모주 및 투자 일임계약은 예금자보호법에 따라 보호되지 않습니다.",
    "공모주는 가격 변동에 따라 투자 원금의 일부 또는 전부 손실이 발생할 수 있으며, 투자 손익은 전부 투자자 본인에게 귀속됩니다.",
    "투자 일임수수료는 투자 일임 계약서상 고객과의 합의에 의하며, 이외에도 거래 수수료 및 청약수수료가 발생합니다.",
    "공모주에 대한 청약의 권유는 주관 증권회사에서 제공하는 투자설명서에 따릅니다.",
    "공모주 일반투자자에게는 균등 배정 방식과 비례 배정 방식이 적용되어 각 배정 방식에 따라 공모주 배정 결과가 다를 수 있습니다.",
    "공모주는 통상 상장 초기 가격 변동성이 크며, 상장 후 시가가 공모가를 하회할 경우 투자 손실이 크게 발생할 수도 있습니다."
  ];


  return (
    <Container>
      <Text>
        <Title>유의사항</Title>
        <Desc>
          {list.map((itm) => {
            return (
              <ListContainer>
                <Dot>·</Dot>
                <Content>{itm}</Content>
              </ListContainer>
            );
          })}
        </Desc>
          <Compliance>엠엘광고준법 심사필 2024-제20호 : 2024.11.4 ~ 2025.11.3</Compliance>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 16px;
  box-sizing: border-box;
  background-color: #e9ebf2;
  min-width: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 360px;
  font-family: 'Spoqa Han Sans Neo', sans-serif;
`;

const Title = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  color: #808491;
`;
const Desc = styled.div`
  display: flex;
  flex-direction: column;
  color: #808491;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 100%;
`;

const Dot = styled.div`
  width: 13px;
  flex-shrink: 0; 
`;

const Content = styled.div`
  flex-grow: 1; 
  color: #808491;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;`;

const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Compliance = styled.div`
  color: #808491;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 13px;
`

export default Index;
