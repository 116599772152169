import React from "react";
import styled from "styled-components";
import Section01 from "./section01/index"
import Section02 from "./section02/index"
import Section03 from "./section03/index"
import Section04 from "./section04/index"
import Section05 from "./section05/index"
import Section06 from "./section06/index"
import Section07 from "./section07/index"
import SamsungEvent from "./samsungEvent/index"
import MobileHeader from "@components/NewMain/Mobile/Header";


const Index = () => {

  const newMobileMain = window.location.href.includes('mobile-main')


  return (
    <Container>
      <MobileHeader />
      {newMobileMain && <SamsungEvent />}
      <Section01 />
      <Section02 />
      <Section03 />
      {/* <Section04 /> */}
      <Section05 />
      <Section06 />
      <Section07 />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 360px;

`;

export default Index;
