import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QR from "./QR";

const DownloadApp = ({ isClicked, setIsClicked }:any) => {
  const [isVisible, setIsVisible] = useState(true);


  // const handleScroll = () => {
  //   const scrollPositionFromBottom =
  //     document.documentElement.scrollHeight -
  //     window.scrollY -
  //     window.innerHeight;
  //   if (scrollPositionFromBottom <= 209) {
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return isClicked ? (
    <Container >
      <TopContainer>
      <Title>일육공 모바일 앱 설치 
        <CloseButton onClick={()=>setIsClicked(!isClicked)}>
          <img src={'/img/X.svg'} alt="X" />
        </CloseButton>
        </Title>
      <DeviceContainer>
      <QR />
      <Content>
          <IPhone
            onClick={() =>
              window.open(
                "https://apps.apple.com/kr/app/%EC%9D%BC%EC%9C%A1%EA%B3%B5-160-%EA%B3%B5%EB%AA%A8%EC%A3%BC-%ED%88%AC%EC%9E%90%EC%9D%98-%ED%99%95%EB%A5%A0%EC%9D%84-%EB%86%92%EC%9D%B4%EB%8B%A4/id1626450484"
              )
            }
          >
            <img src={"/img/download/iPhone.svg"} alt="아이폰" />
            <div>아이폰</div>
          </IPhone>
          <Android
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.qniverse.ipo"
              )
            }
          >
            <img src={"/img/download/Android.svg"} alt="안드로이드" />
            <div>안드로이드폰</div>
          </Android>
      </Content>
      </DeviceContainer>
      </TopContainer>
      <Desc>
        <Phone src={`/img/Phone.svg`}/>
        {`휴대폰 카메라로 QR 코드를 스캔하면 \n 다운로드 화면으로 이동합니다.`}</Desc>
    </Container>
  ) : null;
};

const Container = styled.div`
  width: 369px;
  height: 313px;
  border: 0.0625rem solid #dde1eb;
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0rem 0.75rem 0.5rem 0rem rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 32px;
  top: 109px;
  background-color: #fff;
  z-index: 100;
`;

const TopContainer = styled.div`
  padding: 24px 24px;
`

const DeviceContainer = styled.div`
  display: flex;
  margin-top: 16px;
`
const Phone = styled.img``

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  height: 128px;
  width: 193px;
`;

const Title = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const IPhone = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  gap: 8px;
  cursor: pointer;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
const Android = styled(IPhone)``;
const Desc = styled.div`
  display: flex;
  gap: 8px;
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
  padding: 24px 24px;
  border-top: 1px solid #DDE1EB;
`;

const Device = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export default DownloadApp;
