import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Index = () => {
  return (
    <Container>
      <Content>
        <TopContainer>
          <CrossBrand>일육공 X 삼성증권</CrossBrand>
          <Title>공모주 간편투자 제휴 이벤트</Title>
          <SubTitle>{`공모주 투자일임계약하고 신세계 모바일 상품권 최대 4만원 받으세요.`}
            <Guide>(미션 완료 시 · 선착순 5,000명)</Guide>
          </SubTitle>
        </TopContainer>
        <BottomContainer>
          <LeftContainer>
            <EventTarget>
              <ContentTitle>대상</ContentTitle>
              <Detail>
                일육공에서 삼성증권 계좌개설 후 간편 투자 이용 고객
              </Detail>
            </EventTarget>
            <EventTerm>
              <ContentTitle>기간</ContentTitle>
              <Detail>2024년 9월 27일 ~ 2024년 12월 31일</Detail>
            </EventTerm>
          </LeftContainer>
          <RightContainer>
            <img
              src={'/img/samsung/samsung_gift.png'}
              alt='신세계 모바일 상품권'
            />
          </RightContainer>
        </BottomContainer>
        <Link to="/samsung-event">
        <EventButton>
          이벤트 자세히 보기
        </EventButton>
        </Link>
        <Caution>
          {`※ 투자전 설명 청취 및 상품설명서 필독
            ※ 이 금융상품은 예금자보호법에 따라 보호되지 않습니다.
            ※ 자산가격변동 등에 따른 원금손실(0~100%) 발생가능 및 투자자 귀속
            ※ 청약의 권유는 (예비·간이)투자설명서에 따름

            한국금융투자협회 심사필 제24-03913호 (2024-09-30 ~ 2025-09-29)`}
        </Caution>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 1041px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0E6DFB;
  min-width: 1280px;

`
const Title = styled.div`
  color: #FFF;  
  font-family: 'Pretendard';
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const SubTitle = styled.p`
color: #FFF;
font-family: 'Pretendard';
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 32px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
`

const RightContainer = styled.div`
  img {
    width: 300px;
    display: block;
  }
`

const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;
`

const Explanation = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.33px;
  color: #fff;
  margin-bottom: 24px;
`

const TopContainer = styled.div`
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: column;
`

const ContentTitle = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  font-weight: 400;
  font-family: Pretendard;
  opacity: 0.8;
`

const Detail = styled.div`
  color: #FFF;
  font-size: 29px;
  font-style: normal;
  line-height: 150%;
`

const BottomContainer = styled.div`
  display: flex;
  color: #fff;
  justify-content: space-between;
  padding: 32px 0px;
  box-sizing: border-box;
`

const EventButton = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 16px;
  color: #0E6DFB;
  text-align: center;

  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 32px;
  font-style: normal;
  line-height: 150%;

  font-family: Pretendard700;
  font-weight: 700;
  

`

const CrossBrand = styled.div`
  display: flex;
  color: #fff;
  text-align: right;
  font-size: 22px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 16px;
`

const Guide = styled.div`
  font-family: Pretendard;
  opacity: 0.8;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;


`

const EventTarget = styled.div`
  display:flex;
  flex-direction: column;
`
const EventTerm = styled(EventTarget)``

const Caution = styled.div`
  white-space: pre-line;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.33px;
  opacity: 0.85;
  margin-top: 72px;
  font-family: Pretendard;
  opacity: 0.8;
`

export default Index
